<template>
  <div style="margin-top: 8px;">
    <el-upload
      :show-file-list="false"
      :before-upload="handleBeforeUpload"
      :on-change="handleFileChange"
      :accept="'image/*'"
    >
      <div class="upload">
        <UploadFilled style="width: 1.5em; height: 1.5em;" />
        <span>{{ uploadDesc }}</span>
      </div>
    </el-upload>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import jsQR from 'jsqr';
import { UploadFilled } from '@element-plus/icons-vue';

const props = defineProps(['uploadDesc']);
const emits = defineEmits(['getResult']);

const uploadDesc = ref(props.uploadDesc);
const parsedText = ref('');
const imageUrl = ref('');

const handleBeforeUpload = () => {
  // 阻止默认上传行为
  return false;
};

const handleFileChange = (file) => {
  const reader = new FileReader();
  reader.onload = () => {
    imageUrl.value = reader.result;

    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);
      const imageData = context.getImageData(0, 0, image.width, image.height);
      const code = jsQR(imageData.data, image.width, image.height);
      if (code) {
        parsedText.value = code.data;
        emits("getResult", parsedText.value);
      } else {
        parsedText.value = '未识别到二维码';
        emits("getResult", parsedText.value);
      }
    };
    image.src = reader.result;
  };
  reader.readAsDataURL(file.raw);
};
</script>

<style scoped>
</style>
